import { createStore } from "vuex";

export default createStore({
  state() {
    return {
      dialog: false,
      busy: true,
      config: {
        clientConfig: {},
        customClientConfig: {},
        siteConfig: {},
        userConfig: {},
        userProfile: {},
      },
      allClientConfigOptions: [],
      clientConfigOption: null,
      widget: null,
      siteConfigAdmin: null,
      selectedUser: {},
      reportThemeObject: {},
      customAreas: [],
      reportThemeObjectClone: {},
      indicatorValues: {},
      navigateResult: null,
      viewReportDialog: false,
      reportID: null,
      viewReport: null,
      comparatorsList: Array,
      selectedComparators: [],
      reportMapPrimaryAreaCode: null,
      selectedThemes: null,
      defaultReportObject: { id: null, name: null },
      aggregationProgressPercentage: 0,
      reportDownloading: false,
      reportDownloaded: false,
      reportType: null,
      reportLoadingProgress: 0,
      whichCountryThemes: null,
      serviceIssuesDialogue: false,
      isOcsiMember: false,
      isGroupAdmin: false,
      isPowerUser: false,
      isViewReports: false,
      isPublicUser: false,
      displayOnsSource: false,
      DTSelectedStdGeography: null,
    };
  },
  getters: {
    customClientConfig: (state) => state.config.customClientConfig,
    nationalComparator: (state) => {
      return {
        cac: "National Comparator",
        name: state.config.customClientConfig.national_comparator.country_name,
      };
    },
    siteConfig: (state) => state.config.siteConfig,
    userProfile: (state) => state.config.userProfile,
  },
  mutations: {
    setBusy(state, value) {
      state.busy = value;
    },
    setConfig(state, value) {
      state.config = value;
    },
    resetConfig(state) {
      state.config = {
        clientConfig: {},
        siteConfig: {},
        userConfig: {},
        userProfile: {},
      };
    },
    setCustomClientConfig(state, value) {
      state.config.customClientConfig = value;
    },
    setReportType(state, value) {
      state.reportType = value;
    },
    setAllClientConfigOptions(state, value) {
      state.allClientConfigOptions = value;
    },
    setClientConfigOption(state, value) {
      state.clientConfigOption = value;
    },
    setSelectedClientConfigOption(state, value) {
      state.selectedClientConfigOption = value;
    },
    setSiteConfigAdmin(state, value) {
      state.siteConfigAdmin = value;
    },
    setClientConfigAdmin(state, value) {
      state.clientConfigAdmin = value;
    },
    setUserProfile(state, value) {
      state.config.userProfile = value;
    },
    setSelectedUser(state, value) {
      state.selectedUser = value;
    },
    setReportThemeObject(state, value) {
      state.reportThemeObject = value;
    },
    setReportThemeObjectClone(state, value) {
      state.reportThemeObjectClone = value;
    },
    setIndicatorValues(state, value) {
      state.indicatorValues = value;
    },
    setDialog(state, value) {
      state.dialog = value;
    },
    setCustomAreas(state, value) {
      state.customAreas = value;
    },
    setNavigateResult(state, value) {
      state.navigateResult = value;
    },
    setViewReportDialog(state, value) {
      state.viewReportDialog = value;
    },
    setReportID(state, value) {
      state.reportID = value;
    },
    setViewReport(state, value) {
      state.viewReport = value;
    },
    setComparatorsList(state, value) {
      state.comparatorsList = value;
    },
    setSelectedComparators(state, value) {
      state.selectedComparators = value;
    },
    setReportMapPrimaryAreaCode(state, value) {
      state.reportMapPrimaryAreaCode = value;
    },
    setSelectedThemes(state, value) {
      state.selectedThemes = value;
    },
    setDefaultReportObject(state, value) {
      state.defaultReportObject = value;
    },
    setAggregationProgressPercentage(state, value) {
      state.aggregationProgressPercentage = value;
    },
    setReportDownloading(state, value) {
      state.reportDownloading = value;
    },
    setReportDownloaded(state, value) {
      state.reportDownloaded = value;
    },
    setReportLoadingProgress(state, value) {
      state.reportLoadingProgress = value;
    },
    setServiceIssuesDialogue(state, value) {
      state.serviceIssuesDialogue = value;
    },
    setIsOcsiMember(state, value) {
      state.isOcsiMember = value;
    },
    setIsGroupAdmin(state, value) {
      state.isGroupAdmin = value;
    },
    setIsPowerUser(state, value) {
      state.isPowerUser = value;
    },
    setIsViewReports(state, value) {
      state.isViewReports = value;
    },
    setIsPublicUser(state, value) {
      state.isPublicUser = value;
    },
    setWhichCountryThemes(state, value) {
      state.whichCountryThemes = value;
    },
    setShowIconsOnDashboard(state, value) {
      state.config.userProfile.show_icons_on_dashboard = value;
    },
    setDisplayOnsSource(state, value) {
      state.displayOnsSource = value;
    },
    setDTSelectedStdGeography(state, value) {
      state.DTSelectedStdGeography = value;
    },
  },
});
