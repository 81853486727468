<template>
  <div class="mb-2 px-0 w-100" v-if="loading && loadingStyle === 'skeleton'">
    <v-skeleton-loader type="table-heading" class="px-0"></v-skeleton-loader>
  </div>
  <div v-else class="d-flex mb-2" style="width: 100%">
    <div class="d-flex flex-grow-1">
      <v-select
        id="data-level-select"
        label="Current Level"
        width="100%"
        variant="outlined"
        rounded="0"
        :readonly="lockedView"
        :aria-disabled="lockedView"
        v-model="selectedDataLevel"
        :items="indicatorSelectOptions"
        :error-messages="selectErrorMsg"
        :loading="loading && loadingStyle === 'default'"
        :disabled="loading"
        @click="if (lockView) setSelectErrorMsg();"
      >
        <template v-slot:item="{ item, props }">
          <v-list-item
            v-bind="props"
            :active="item.title === selectedDataLevel"
            :title="item.title"
            :value="item.value"
          ></v-list-item>
        </template>
      </v-select>
    </div>
    <v-tooltip
      open-delay="500"
      max-width="110"
      :text="`${toggleLockViewVerb} Selected Data Level`"
      location="bottom"
      content-class="pa-1 rounded-0 text-center"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          id="lock-btn"
          variant="outlined"
          rounded="0"
          class="d-flex h-auto ml-3"
          :class="lockView ? 'bg-error' : ''"
          v-bind="props"
          :aria-label="`${toggleLockViewVerb} selected data level`"
          :aria-pressed="lockView"
          @click="toggleLockView"
          :disabled="loading"
        >
          <v-icon :color="lockView ? 'white' : 'success'" size="24">
            {{ lockView ? "mdi-lock" : "mdi-lock-open" }}
          </v-icon>
        </v-btn>
      </template>
    </v-tooltip>
  </div>
</template>

<script>
/**
 * Component renders a dropdown list of area data levels from  1 (lsoa)
 * to max level set in indicatorSelectOptions (currently 6 => LA/CA/UA/SA3).
 * There is a button to lock the selection
 */
export default {
  name: "MapDataLevelSelect",
  data: () => ({
    lockView: false,
    selectErrorMsg: [],
  }),
  emits: ["selectedDataLevelChanged", "lockViewChanged"],
  computed: {
    /** returns array of area data levels for dropdown */
    indicatorSelectOptions() {
      //show LSOA as lowest select option or lowestDataLevelId if >
      const min = this.lowestDataLevelId > 0 ? this.lowestDataLevelId : 1;
      const max = 6; //LA
      //fetch area level names from vuex
      let indicatorDataLevels = [];
      this.$store.state.config.siteConfig.area_level_names.forEach((item) => {
        if (item.area_level >= min && item.area_level <= max) {
          indicatorDataLevels.push({
            title: item.area_level_name,
            value: item.area_level,
          });
        }
      });
      //sort and return area level names to v-select
      return indicatorDataLevels
        .sort((a, b) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        })
        .reverse();
    },
    /**
     * gets or sets selected option for the data display level dropdown
     * based on parent map zoom level if unlocked
     */
    selectedDataLevel: {
      get: function () {
        if (this.selectedDataLevelId == undefined) {
          return null;
        }

        return this.$store.state.config.siteConfig.area_level_names.find(
          (item) => item.area_level === this.selectedDataLevelId,
        )["area_level_name"];
      },
      set: function (newValue) {
        this.$emit("selectedDataLevelChanged", newValue);
      },
    },
    /**
     * returns opposite verb for current lock view status
     * e.g. 'unlock' when lockView is true or 'lock' when false
     */
    toggleLockViewVerb: {
      get: function () {
        return this.lockView ? "Unlock" : "Lock";
      },
    },
  },
  props: {
    /**
     * The selected data level id
     */
    selectedDataLevelId: {
      type: Number,
    },
    /**
     * The lowest data level id to be displayed (optional)
     * e.g. determined by data indicator starting
     * at 2 => msoa
     */
    lowestDataLevelId: {
      type: Number,
      default: 0,
    },
    /**
     * parent map view lockView state (to prevent scroll changes) (optional)
     */
    lockedView: {
      type: Boolean,
      default: false,
    },
    /**
     * loading state to trigger animation (optional)
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * loading style - default or skeleton (optional)
     */
    loadingStyle: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["default", "skeleton"].includes(value);
      },
    },
  },
  mounted() {},
  methods: {
    toggleLockView() {
      this.lockView = !this.lockView;
      this.$emit("lockViewChanged", this.lockView);
    },
    setSelectErrorMsg() {
      this.selectErrorMsg = "Data Level Selection is Locked";
      setTimeout(() => {
        this.selectErrorMsg = [];
      }, 3000);
    },
  },
  watch: {
    lockedView: {
      handler() {
        this.lockView = this.lockedView;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.v-select__selections > input {
  display: none;
}

.v-select__selection {
  min-width: fit-content;
}

.v-input.v-input--disabled :deep(.v-input__control .v-field) {
  opacity: 0.85;
}

#lock-btn {
  border: thin solid #a4a4a4;
  margin-bottom: 22px;
}

.v-skeleton-loader {
  width: 100%;
  transform: rotate(180deg);
}

:deep(.v-skeleton-loader__text) {
  /* rotated 180 degrees! */
  margin-right: 0px !important;
}
</style>
