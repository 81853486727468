<template>
  <div>
    <v-dialog v-model="dialog" width="600">
      <template v-slot:activator="{ props }">
        <v-btn
          class="ml-2"
          icon="mdi-pencil"
          variant="text"
          tile
          @click="dialog = true"
          v-bind="props"
          aria-label="edit indicator"
        />
      </template>
      <v-card-title
        :style="
          'background-color: ' +
          this.$store.state.config.siteConfig.toolbar_colour
        "
        class="text-h6 pl-1"
      >
        <v-btn
          icon="mdi-close"
          variant="text"
          tile
          @click="dialog = false"
          aria-label="Select Indicator"
        />
        Map indicator data
      </v-card-title>
      <v-card class="pa-0" rounded="0">
        <v-card-text
          class="pa-0 mt-2 radioList"
          style="max-height: 600px; overflow: auto"
        >
          <v-radio-group v-model="localIndicator" color="primary">
            <v-radio
              v-for="indicator in indicators"
              :label="indicator.indicator_name"
              :value="indicator"
              v-bind:key="indicator.id"
              :aria-label="`select ${indicator.indicator_name}`"
              class="py-1"
              :class="{ highlighted: indicator.id === localIndicator.id }"
            />
          </v-radio-group>
        </v-card-text>
        <v-card>
          <v-card-actions>
            <v-btn
              color="error"
              tile
              @click="dialog = false"
              variant="elevated"
              title="back to list"
              aria-label="close"
            >
              close
            </v-btn>
            <v-spacer />
            <v-btn
              color="success"
              tile
              variant="elevated"
              @click="updateMap()"
              title="back to list"
              aria-label="Update map"
            >
              update map
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DiscoveryToolSelectIndicator",
  data: () => ({
    dialog: false,
    localIndicator: { id: null },
  }),
  props: {
    indicators: {
      required: true,
    },
    selectedIndicatorProp: {
      required: false,
    },
  },
  methods: {
    updateMap() {
      this.selectedIndicator = JSON.parse(JSON.stringify(this.localIndicator));
      this.dialog = false;
    },
  },
  computed: {
    selectedIndicator: {
      get() {
        return this.selectedIndicatorProp;
      },
      set(val) {
        this.$emit("update:selectedIndicatorProp", val);
      },
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        // open
        this.localIndicator = JSON.parse(
          JSON.stringify(this.selectedIndicator),
        );
      } else {
        // close
        this.localIndicator = { id: null };
      }
    },
  },
};
</script>

<style>
.radioList .v-label--clickable,
.radioList .v-selection-control__input {
  margin-left: 15px;
}

.radioList .v-label {
  opacity: 1 !important;
}

.highlighted {
  background-color: #bedef6;
  transition: background-color 0.5s;
}
</style>
