<template>
  <div class="ma-0 pa-0">
    <v-dialog
      v-model="viewReportDialog"
      fullscreen
      hide-overlay
      persistent
      scrollable
    >
      <v-card v-if="viewReportDialog">
        <v-toolbar
          :style="
            'background-color: ' + $store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-btn
            icon
            dark
            id="closeReportViewerIcon"
            @click="
              reportDownloading
                ? backgroundReportGeneration()
                : (viewReportDialog = false)
            "
            aria-label="Close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="isReportManager">{{
            isReportManager
          }}</v-toolbar-title>
          <v-toolbar-title v-else>Report</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text style="height: 95vh" @scroll="lazyLoad">
          <!-- loading UX -->
          <v-card
            v-if="reportLoadingDialog"
            tile
            elevation="0"
            class="progress"
          >
            <v-card-actions class="justify-center">
              <v-progress-circular
                v-if="reportLoadingProgress == 0"
                :size="210"
                :width="2"
                color="#51627C"
                indeterminate
              >
                Retrieving Report
              </v-progress-circular>
              <v-progress-circular
                v-else-if="reportLoadingProgress < 100"
                :size="210"
                :width="2"
                color="#51627C"
                :rotate="-90"
                :model-value="reportLoadingProgress"
              >
                {{ reportLoadingMessage }}
                {{ Math.ceil(reportLoadingProgress) }}%
              </v-progress-circular>
              <v-progress-circular
                v-else
                :size="210"
                :width="2"
                color="#51627C"
                indeterminate
              >
                <div
                  class="text-center"
                  v-html="
                    'Aggregating...' +
                    Math.ceil(aggregationProgressPercentage) +
                    '%,<br>This could take a<br>few minutes.'
                  "
                />
              </v-progress-circular>
            </v-card-actions>
          </v-card>
          <!--   load in background block       -->
          <v-card
            v-if="reportLoadingDialog"
            elevation="0"
            style="position: relative; top: 20dvh"
          >
            <v-card-actions class="justify-center">
              <div class="text-center loadInBackground">
                <v-card-text style="font-size: 16px">
                  If you want you can load this report in the background and
                  continue using the site whilst you wait.
                </v-card-text>
                <v-btn
                  color="primary"
                  variant="elevated"
                  class="ma-2"
                  tile
                  title="Load Report in Background"
                  @click="backgroundReportGeneration"
                  aria-label="Load"
                >
                  Load in Background
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
          <!-- Report content -->
          <div v-if="!reportLoadingDialog">
            <v-card-actions class="downloadButton">
              <v-spacer></v-spacer>
              <v-btn
                v-if="
                  this.$store.state.config.siteConfig.is_public_site &&
                  this.$store.state.isGroupAdmin
                "
                color="warning"
                class="ma-3 white--text"
                tile
                title="click to create a public report"
                @click="saveReport(1)"
                :disabled="disablePubButton"
                variant="elevated"
                :loading="savingPub"
                aria-label="Use as a Public Report"
              >
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
                Use as a Public Report
                <v-icon class="ml-2"> mdi-chart-bar-stacked</v-icon>
              </v-btn>
              <v-btn
                v-if="
                  !this.$store.state.config.userProfile
                    .is_public_site_user_profile &&
                  this.reportType !== 'discovery'
                "
                color="info"
                class="ma-2 white--text"
                tile
                title="Add to favourites"
                @click="saveReport()"
                :disabled="disableFavButton"
                :loading="savingFav"
                aria-label="Add to Favourites"
                append-icon="mdi-heart-outline"
                variant="elevated"
              >
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
                <template v-slot:append>
                  <v-icon></v-icon>
                </template>
                Add to Favourites
              </v-btn>
              <v-btn
                color="success"
                class="ma-2 white--text"
                tile
                title="Download Report"
                @click="downloadFullReport()"
                aria-label="download"
                append-icon="mdi-download"
                variant="elevated"
                :disabled="mapGenerating"
              >
                download
                <template v-slot:append>
                  <v-icon></v-icon>
                </template>
              </v-btn>
            </v-card-actions>
            <v-card-text style="min-width: 600px" class="mt-8 flexcard" fluid>
              <v-row class="ma-0" justify="center">
                <v-col cols="6">
                  <a href="https://localinsight.org/" target="_blank">
                    <v-img
                      :src="require('@/assets/localInsight.png')"
                      :width="250"
                      class="mr-auto"
                      alt="Site Logo"
                    ></v-img>
                  </a>
                </v-col>
                <v-col mb-6 cols="6">
                  <a :href="logoRedirectionURL" target="_blank">
                    <v-img
                      :src="reportLogo"
                      :width="250"
                      class="ml-auto"
                      alt="OCSI Logo"
                    ></v-img>
                  </a>
                </v-col>
              </v-row>
              <v-row justify="center" class="mt-15">
                <v-col cols="6" class="pa-0">
                  <v-card-text>
                    <h1
                      class="mb-16"
                      :style="'font-size: ' + titleSize + 'px;'"
                    >
                      {{
                        reportType === "Summary"
                          ? reportObject.name
                          : reportObject.custom_report_name
                      }}
                    </h1>
                    <h1
                      v-if="comparatorsList[0]"
                      class="my-16"
                      :style="
                        'font-size: ' + subtitleSize + 'px; line-height: 1.3;'
                      "
                      id="customAreaTitleName"
                    >
                      {{ comparatorsList[0].area_name }}
                    </h1>
                    <h1
                      class="my-16"
                      :style="'font-size: ' + subtitleSize + 'px'"
                    >
                      {{ config.customClientConfig.client_name }}
                    </h1>
                    <h2 class="my-16">{{ date }}</h2>
                  </v-card-text>
                </v-col>
                <v-col cols="6" class="pa-0">
                  <div
                    v-if="mapGenerating"
                    class="d-flex fill-height justify-center align-center"
                  >
                    <v-progress-circular indeterminate :size="128" :width="2">
                      <div class="text-center">
                        Generating Map
                      </div></v-progress-circular
                    >
                  </div>
                  <v-img
                    v-if="!isReportManager && !mapGenerating"
                    id="mapImgHTML"
                    width="80%"
                    min-width="600"
                    class="ml-auto"
                    :src="mapImage"
                    alt="Map of Reports Primary Area"
                    @error="
                      mapImage = require('@/assets/mapPlaceholder.png');
                      this.errorMsg({
                        title: 'Unable to generate map image',
                        message:
                          'The map for this area is too complex to generate and therefore cannot be displayed',
                      });
                    "
                  >
                  </v-img>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="mt-8">
              <v-card-text class="pl-2">
                <h1 v-if="reportObject.introduction" class="mb-7 mt-5">
                  Introduction
                </h1>
                <div
                  v-html="reportObject.introduction"
                  class="min-accessible-size text"
                />
                <h1 v-if="reportObject.site_branding" class="mb-7 mt-5">
                  Local Insight
                </h1>
                <div
                  v-html="reportObject.site_branding"
                  class="min-accessible-size text"
                />
                <h1 v-if="reportObject.ocsi_branding" class="mb-7 mt-5">
                  OCSI
                </h1>
                <div
                  v-html="reportObject.ocsi_branding"
                  class="min-accessible-size text"
                />
                <h1 v-if="reportObject.about_ind" class="mb-7 mt-5">
                  About the indicators
                </h1>
                <div
                  v-html="reportObject.about_ind"
                  class="min-accessible-size text"
                />
                <h1 v-if="reportObject.how_area_identified" class="mb-7 mt-5">
                  About the area
                </h1>
                <div
                  v-html="reportObject.how_area_identified"
                  class="min-accessible-size text"
                />
              </v-card-text>
            </v-card-actions>
            <div
              v-for="(theme, i) in reportThemes"
              :key="i"
              style="min-width: 600px"
            >
              <h2 class="ml-4">{{ theme.title }}</h2>
              <v-card-text>
                <div
                  v-html="theme.description"
                  class="min-accessible-size text"
                />
              </v-card-text>
              <v-card-actions v-for="(item, j) in theme.config" :key="j">
                <!-- Text -->
                <v-card-text v-if="item.text">
                  <div v-html="item.text" class="min-accessible-size text" />
                </v-card-text>
                <!-- KPIs -->
                <v-card-actions v-if="item.kpi_config" style="width: 94vw">
                  <KPIs :KPIsObject="item.kpi_config"></KPIs>
                </v-card-actions>
                <!-- Table -->
                <v-card-actions
                  v-if="item.indicatorHTMLTableRef"
                  :id="i.toString() + '-' + j.toString()"
                >
                  <Tables
                    :load="loadItemTriggers[i.toString() + '-' + j.toString()]"
                    :item="item"
                    :indicatorValues="indicatorValues"
                    :comparators="selectedComparators"
                    :comparatorsList="comparatorsList"
                    :reportThemeObject="theme"
                  />
                </v-card-actions>
                <!-- Chart -->
                <v-card-actions
                  v-if="item.chartType"
                  :id="i.toString() + '-' + j.toString()"
                >
                  <Charts
                    :load="loadItemTriggers[i.toString() + '-' + j.toString()]"
                    :item="item"
                    :indicatorValues="indicatorValues"
                    :comparators="selectedComparators"
                    :comparatorsList="comparatorsList"
                    :reportThemeObject="theme"
                  />
                </v-card-actions>
              </v-card-actions>
              <v-divider
                style="margin-top: 50px; margin-bottom: 50px"
              ></v-divider>
            </div>
          </div>
          <ReportDocX
            v-model:download="download"
            :content="content"
            :indicatorValues="indicatorValues"
            :comparators="selectedComparators"
            :comparatorsList="comparatorsList"
            :reportMapPrimaryAreaCode="reportMapPrimaryAreaCode"
            :isReportManager="isReportManager"
            :mapImage="mapImage"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Charts from "@/components/Charts";
import Tables from "@/components/Tables";
import KPIs from "@/components/KPIs";
import ReportDocX from "@/components/ReportDocX";
import OCSILogo from "@/assets/OCSILogo.png";
import { systemMessages } from "@/mixins/SystemMessages";

export default {
  name: "REPORTVIEWER",
  data: () => ({
    disablePubButton: true,
    disableFavButton: true,
    savingFav: false,
    savingPub: false,
    loadItemTriggers: {},
    allChartsLoaded: false,
    chartCounter: 0,
    reportObject: null,
    reportLoadingDialog: false,
    reportLoadingMessage: null,
    reportThemes: [],
    indicatorValues: {},
    download: false,
    content: {},
    timer: null,
    importComplete: false,
    progressCheckLimit: 0,
    progressID: null,
    config: null,
    date: null,
    mapGenerating: false,
    mapImage: null,
  }),
  components: {
    Charts,
    Tables,
    KPIs,
    ReportDocX,
  },
  mixins: [systemMessages],
  props: {
    isReportManager: {
      type: String,
      default: null,
    },
  },
  computed: {
    saveFileName() {
      return (
        "Local Insight " +
        this.comparatorsList[0].area_name +
        " " +
        this.$store.state.reportType +
        " Report"
      );
    },
    reportLogo() {
      return this.config.customClientConfig.report_logo || OCSILogo;
    },
    logoRedirectionURL() {
      let logo = this.config.customClientConfig.logo_redirection;

      if (logo && !logo.startsWith("https")) {
        return "https://" + logo;
      }

      return logo;
    },
    defaultReportObject: {
      get() {
        return this.$store.state.defaultReportObject;
      },
      set(value) {
        this.$store.commit("setDefaultReportObject", value);
      },
    },
    reportType: {
      get() {
        return this.$store.state.reportType;
      },
      set(value) {
        this.$store.commit("setReportType", value);
      },
    },
    viewReportDialog: {
      get() {
        return this.$store.state.viewReportDialog;
      },
      set(value) {
        this.$store.commit("setViewReportDialog", value);
      },
    },
    reportDownloading: {
      get() {
        return this.$store.state.reportDownloading;
      },
      set(value) {
        this.$store.commit("setReportDownloading", value);
      },
    },
    reportDownloaded: {
      get() {
        return this.$store.state.reportDownloaded;
      },
      set(value) {
        this.$store.commit("setReportDownloaded", value);
      },
    },
    aggregationProgressPercentage: {
      get() {
        return this.$store.state.aggregationProgressPercentage;
      },
      set(value) {
        this.$store.commit("setAggregationProgressPercentage", value);
      },
    },
    reportLoadingProgress: {
      get() {
        return this.$store.state.reportLoadingProgress;
      },
      set(value) {
        this.$store.commit("setReportLoadingProgress", value);
      },
    },
    reportID: {
      get() {
        return this.$store.state.reportID;
      },
      set(value) {
        this.$store.commit("setReportID", value);
      },
    },
    viewReport: {
      get() {
        return this.$store.state.viewReport;
      },
      set(value) {
        this.$store.commit("setViewReport", value);
      },
    },
    comparatorsList: {
      get() {
        return this.$store.state.comparatorsList;
      },
      set(value) {
        this.$store.commit("setComparatorsList", value);
      },
    },
    selectedComparators: {
      get() {
        return this.$store.state.selectedComparators;
      },
      set(value) {
        this.$store.commit("setSelectedComparators", value);
      },
    },
    reportMapPrimaryAreaCode: {
      get() {
        return this.$store.state.reportMapPrimaryAreaCode;
      },
      set(value) {
        this.$store.commit("setReportMapPrimaryAreaCode", value);
      },
    },
    whichCountryThemes: {
      get() {
        return this.$store.state.whichCountryThemes;
      },
      set(value) {
        this.$store.commit("setWhichCountryThemes", value);
      },
    },
    selectedThemes: {
      get() {
        return this.$store.state.selectedThemes;
      },
      set(value) {
        this.$store.commit("setSelectedThemes", value);
      },
    },
    titleSize() {
      if (this.$vuetify.display.xs) {
        return "20";
      }
      if (this.$vuetify.display.sm) {
        return "25";
      }
      if (this.$vuetify.display.md) {
        return "25";
      }
      if (this.$vuetify.display.lg) {
        return "40";
      }
      if (this.$vuetify.display.xl) {
        return "40";
      }
      return "25";
    },
    subtitleSize() {
      if (this.$vuetify.display.xs) {
        return "20";
      }
      if (this.$vuetify.display.sm) {
        return "20";
      }
      if (this.$vuetify.display.md) {
        return "25";
      }
      if (this.$vuetify.display.lg) {
        return "30";
      }
      if (this.$vuetify.display.xl) {
        return "30";
      }
      return "25";
    },
    customAreaID() {
      // if the area code hasn't been passed in as a prop, this will be a 'sample' report, so use a known area code
      if (this.reportMapPrimaryAreaCode) {
        return this.reportMapPrimaryAreaCode;
      } else {
        return 146;
      }
    },
  },
  mounted() {
    this.config = this.$store.state.config;

    const options = { year: "numeric", month: "long", day: "numeric" };
    this.date = new Date().toLocaleDateString(undefined, options);
  },
  unmounted() {},
  methods: {
    favPubButtonsController(publicReport = 0) {
      this.disableFavButton = true;
      this.disablePubButton = true;
      var currentReport = {
        public_report: publicReport,
        comparitor_one: this.comparatorsList[0].area_code,
        comparitor_two: this.comparatorsList[1].area_code,
        comparitor_three: this.comparatorsList[2].area_code,
        assigned_themes: this.selectedThemes.map((theme) => theme.themeID),
      };

      this.$axios
        .post("/saved-report-duplication-check", currentReport)
        .then(
          function (response) {
            // handle success
            this.disableFavButton = response.data[0];
            this.disablePubButton = response.data[1];
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
          }.bind(this),
        );
    },
    buildChartTriggers() {
      this.loadItemTriggers = {};
      this.allChartsLoaded = false;
      for (var i = 0; i < this.reportThemes.length; i++) {
        for (var j = 0; j < this.reportThemes[i].config.length; j++) {
          if (
            this.reportThemes[i].config[j].chartType ||
            this.reportThemes[i].config[j].indicatorHTMLTableRef
          ) {
            this.loadItemTriggers[i.toString() + "-" + j.toString()] = false;
          }
        }
      }
    },
    // add a debouncer
    lazyLoad() {
      for (const key in this.loadItemTriggers) {
        this.isInViewport(document.getElementById(key), key);
      }
    },
    isInViewport(element, id) {
      var bounding = element.getBoundingClientRect();
      if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        this.loadItemTriggers[id] = true;
      } else {
        return false;
      }
    },
    backgroundReportGeneration() {
      if (this.reportType === "discovery") {
        this.viewReportDialog = false;
        return;
      } else if (this.$router.currentRoute.path !== "/map") {
        this.$router.push("/map");
      }
      this.viewReportDialog = false;
    },
    saveReport(publicReport = 0) {
      this.savingPub = !!publicReport;
      this.savingFav = !publicReport;
      var savedReport = {
        name: null,
        description: null,
        summary_report: false,
        public_report: publicReport,
        comparitor_one: this.comparatorsList[0].area_code,
        comparitor_two: this.comparatorsList[1].area_code,
        comparitor_three: this.comparatorsList[2].area_code,
        assigned_themes: [],
      };

      // custom report assigned themes
      if (this.selectedThemes.length) {
        for (var i = 0; i < this.selectedThemes.length; i++) {
          savedReport.assigned_themes.push(this.selectedThemes[i].themeID);
        }
      }
      this.$axios
        .post("/saved-reports", savedReport)
        .then(
          function () {
            // handle success
            this.disableFavButton = this.disableFavButton
              ? this.disableFavButton
              : !publicReport;
            this.disablePubButton = this.disablePubButton
              ? this.disablePubButton
              : !!publicReport;
            this.savingPub = false;
            this.savingFav = false;
            // let the user know
            this.emit.emit("systemMessage", {
              title: publicReport ? "Public Reports" : "Favourites",
              message: publicReport
                ? "This report is now available to the Public"
                : "This report has been added to your Favourites",
              colour: "success",
              timeout: 3000,
            });
          }.bind(this),
        )
        .catch(
          function (error) {
            this.savingPub = false;
            this.savingFav = false;
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to Save Report",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getStaticMapURL() {
      /**
       * Report requires a map image or image placeholder in order
       * for docx download not to fail.
       *
       * two known failures during map generation:
       * - dissolving a complex geometry takes too long and times out eventually
       * - the google maps api returns an error e.g. url too long
       *
       * +reasonable timeout added if backend taking too long with failure handling
       * +v-img error handler deals with src error e.g. google maps api
       */
      this.mapGenerating = true;
      this.$axios
        .post("/custom-areas-static-map", [this.customAreaID], {
          timeout: 10000, //timeout call to backend after (ms)
        })
        .then(
          function (response) {
            // handle success
            this.mapImage = response.data;
            this.mapGenerating = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            if (error.code === "ECONNABORTED") {
              //handle timeout error
              this.mapImage = require("@/assets/mapPlaceholder.png");
              this.errorMsg({
                title: "Unable to generate map image",
                message:
                  "The map for this area is too complex to generate and therefore cannot be displayed",
              });
              this.mapGenerating = false;
            } else {
              // handle other errors
              console.error(error);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Unable to load map :(",
                timeout: -1,
                colour: "warning",
              });
              this.mapGenerating = false;
            }
          }.bind(this),
        );
    },
    downloadFullReport() {
      this.content = {
        reportTitle:
          this.reportType === "Summary"
            ? this.reportObject.name
            : this.reportObject.custom_report_name,
        description: this.reportObject.description,
        saveFileName: this.saveFileName,
        themes: this.reportThemes,
        reportObject: this.reportObject,
        clientName: this.$store.state.config.customClientConfig.client_name,
        reportLogo: this.$store.state.config.customClientConfig.report_logo,
      };

      this.download = true;
      setTimeout(
        function () {
          for (const key in this.loadItemTriggers) {
            this.loadItemTriggers[key] = true;
          }
        }.bind(this),
        1800,
      );
    },
    setIndicatorValues() {
      this.importComplete = false;
      this.indicatorValues = [];
      this.aggregationProgressPercentage = 0;
      this.progressID = Math.floor(Math.random() * 10000) + 1;
      var tempIndicatorValues = {};
      var newKey = null;
      for (var h = 0; h < this.reportThemes.length; h++) {
        for (var i = 0; i < this.reportThemes[h].config.length; i++) {
          for (
            var j = 0;
            j < this.reportThemes[h].config[i].indicators.length;
            j++
          ) {
            for (var k = 0; k < this.comparatorsList.length; k++) {
              newKey =
                this.comparatorsList[k].area_code +
                "." +
                this.reportThemes[h].config[i].indicators[j].indicatorCode +
                "." +
                this.reportThemes[h].config[i].indicators[j].date +
                "." +
                this.reportThemes[h].config[i].indicators[j].indicatorName;
              if (typeof this.indicatorValues[newKey] === "undefined") {
                this.indicatorValues[newKey] = null;
              }
              tempIndicatorValues[newKey] = this.indicatorValues[newKey];
            }
          }
        }
      }
      tempIndicatorValues.progressID = this.progressID;
      setTimeout(
        function () {
          this.aggregationProgress();
        }.bind(this),
        2000,
      );
      this.$axios
        .post("/indicator-values-by-area", tempIndicatorValues)
        .then(
          function (response) {
            // handle success
            this.indicatorValues = response.data;
            this.reportLoadingDialog = false;
            this.reportDownloaded = true;
            this.reportDownloading = false;
            if (this.isReportManager === null) {
              this.getStaticMapURL();
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            clearTimeout(this.timer);
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get Indicator Values",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    aggregationProgress() {
      clearTimeout(this.timer);
      if (this.importComplete || this.progressCheckLimit > 9999999999) {
        return;
      }
      // get progress
      this.$axios
        .get("/get-progress/" + this.progressID)
        .then(
          function (response) {
            // handle success
            clearTimeout(this.timer);
            this.progressCheckLimit++;
            this.importComplete = response.data.complete;
            if (response.data.current_total) {
              this.aggregationProgressPercentage = Math.round(
                (response.data.current_total / response.data.expected_total) *
                  100,
              );
            }
            this.timer = setTimeout(
              function () {
                this.aggregationProgress();
              }.bind(this),
              7000,
            );
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
          }.bind(this),
        );
    },
    loadThemes() {
      this.reportLoadingProgress =
        this.reportThemes.length *
        (100 / this.reportObject.assigned_themes.length);
      if (
        this.reportThemes.length !== this.reportObject.assigned_themes.length
      ) {
        this.getTheme(
          this.reportObject.assigned_themes[this.reportThemes.length].themeID,
        );
      } else {
        this.reportLoadingMessage = "Aggregating Data for Selected Areas...";
        this.setIndicatorValues();
      }
    },
    getTheme(themeID) {
      let i = 0;
      let areadCode = null;
      let url = "/reports-themes/" + themeID;
      while (i < 3) {
        areadCode = null;
        if (typeof this.selectedComparators[i] !== "undefined") {
          areadCode = this.selectedComparators[i];
        }
        url = url + "/" + areadCode;
        i++;
      }
      this.$axios
        .get(url)
        .then(
          function (response) {
            // handle success
            this.reportThemes.push(response.data);
            this.loadThemes();
            this.buildChartTriggers();
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get selected report",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    sendAnalytics() {
      this.$axios.put("/send-analytics", {
        event: "reports_usage",
        reportID: this.reportID,
        summary:
          !this.isReportManager && this.selectedThemes.length ? "No" : "Yes",
        c1: {
          areaName: this.comparatorsList[0].area_name,
          areaCode: this.comparatorsList[0].area_code,
        },
        c2: {
          areaName: this.comparatorsList[1].area_name,
          areaCode: this.comparatorsList[1].area_code,
        },
        c3: {
          areaName: this.comparatorsList[2].area_name,
          areaCode: this.comparatorsList[2].area_code,
        },
        themes: this.selectedThemes,
      });
    },
    getSelectedReport() {
      // send some analytics
      this.sendAnalytics();

      // update config
      this.config = this.$store.state.config;
      this.reportObject = null;
      this.mapImage = null;
      this.reportThemes = [];
      let i = 0;
      let areadCode = null;
      let url = "/reports/" + this.reportID;
      while (i < 3) {
        areadCode = null;
        if (typeof this.comparatorsList[i] !== "undefined") {
          areadCode = this.comparatorsList[i].area_code;
        }
        url = url + "/" + areadCode;
        i++;
      }
      if (this.reportType === "discovery") {
        url = url + "/" + true;
      }
      this.$axios
        .get(url)
        .then(
          function (response) {
            // handle success
            this.reportObject = response.data;
            this.whichCountryThemes =
              this.reportObject.which_country_themes.substring(0, 1);

            // if it's custom report then get only selected themes
            if (!this.isReportManager && this.selectedThemes.length) {
              this.reportObject.assigned_themes = this.selectedThemes;
            } else {
              // filter themes by country
              this.reportObject.assigned_themes =
                this.reportObject.assigned_themes.filter((item) => {
                  return item.country === this.whichCountryThemes;
                });
            }
            this.loadThemes();
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get selected report",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    initiateReportGeneration() {
      this.favPubButtonsController();
      this.reportDownloading = true;
      this.reportDownloaded = false;
      this.reportLoadingMessage = "Loading Themes...";
      this.reportLoadingProgress = 0;
      this.reportLoadingDialog = true;
      this.viewReportDialog = true;
      this.getSelectedReport();
    },
    checkDemoAccountReportLimit() {
      let currentReport = {
        comparitor_one: this.comparatorsList[0].area_code,
        comparitor_two: this.comparatorsList[1].area_code,
        comparitor_three: this.comparatorsList[2].area_code,
        assigned_themes: this.selectedThemes.map((theme) => theme.themeID),
      };

      this.$axios
        .post("/check-report-limit", currentReport)
        .then(
          function (response) {
            if (response.data.reports_left >= 0 && response.data.success) {
              // show how many reports they've got left and generate the report
              this.emit.emit("systemMessage", {
                message: response.data.message,
                title: response.data.header,
                timeout: 5000,
                colour: "info",
              });
              this.initiateReportGeneration();
            } else if (response.data.reports_left <= 0) {
              // sorry... no reports left
              this.emit.emit("systemMessage", {
                message: response.data.message,
                title: response.data.header,
                timeout: 5000,
                colour: "warning",
              });
            } else {
              // otherwise it's a report from favorites, so just carry on
              this.initiateReportGeneration();
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed",
              timeout: -1,
              colour: "info",
            });
          }.bind(this),
        );
    },
  },
  watch: {
    viewReport: {
      handler() {
        if (this.viewReport) {
          // if it's a demo account then check their limit
          if (
            this.$store.state.config.customClientConfig.client_type === "demo"
          ) {
            this.checkDemoAccountReportLimit();
          } else {
            // no limits, just crack on
            this.initiateReportGeneration();
          }
        }
      },
      immediate: true,
    },
    viewReportDialog: {
      handler() {
        if (!this.viewReportDialog) {
          for (const key in this.loadItemTriggers) {
            this.loadItemTriggers[key] = false;
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.downloadButton {
  position: fixed;
  right: 10px;
  top: -5px;
}

.progress {
  top: 10vh;
}

.loadInBackground {
  padding: 20px;
  border-color: #dedbdb;
  border-radius: 0px;
  border-width: 0.1px;
  border-style: solid;
}
.text::v-deep p {
  margin-bottom: 20px !important;
}
</style>
